import { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import {
  getDatabase,
  ref,
  onValue,
  set,
  push,
  serverTimestamp,
  update,
  get
} from "firebase/database";  // Импорт функций из Firebase Realtime Database
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";  // Импорт функций из Firebase Authentication
import { db, auth } from './firebaseConfig';

const GRID_SIZE_WIDTH = 128;
const GRID_SIZE_HEIGHT = 64;
const PIXEL_SIZE = 8;
const DEFAULT_COLOR = "#ffffff";
const COLORS = ["#000000", "#ff0000", "#00ff00", "#0000ff", "#ffff00", "#ff00ff", "#00ffff"];
const CLICK_COOLDOWN_MS = 5000;

export default function PixelBattle() {
  const [grid, setGrid] = useState(Array(GRID_SIZE_WIDTH * GRID_SIZE_HEIGHT).fill(DEFAULT_COLOR));
  const [selectedColor, setSelectedColor] = useState("#000000");
  const [lastClickTime, setLastClickTime] = useState(0);
  const [userId, setUserId] = useState(null);
  const [username, setUsername] = useState("Anonymous");
  const [chat, setChat] = useState([]);
  const [message, setMessage] = useState("");
  const [stats, setStats] = useState({});
  const [isPlayingTimeLapse, setIsPlayingTimeLapse] = useState(false);

  useEffect(() => {
    signInAnonymously(auth).catch(console.error);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        setUserId(uid);
        const randomName = "Player_" + uid.substring(0, 5);
        setUsername(randomName);
      }
    });
  }, []);

  useEffect(() => {
    const gridRef = ref(db, "grid");
    onValue(gridRef, (snapshot) => {
      const data = snapshot.val();
      if (data && Array.isArray(data)) {
        setGrid(data);
      }
    });

    const chatRef = ref(db, "chat");
    onValue(chatRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setChat(Object.values(data));
      }
    });

    const statsRef = ref(db, "stats");
    onValue(statsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setStats(data);
      }
    });
  }, []);

  const paintPixel = (index) => {
    const now = Date.now();
    if (now - lastClickTime < CLICK_COOLDOWN_MS || isPlayingTimeLapse) return;

    const newGrid = [...grid];
    newGrid[index] = selectedColor;
    set(ref(db, "grid"), newGrid);

    const historyRef = ref(db, `history/${Date.now()}`);
    set(historyRef, {
      index,
      color: selectedColor,
      user: username,
      timestamp: serverTimestamp()
    });

    const userRef = ref(db, `stats/${username}`);
    update(userRef, {
      count: (stats[username]?.count || 0) + 1
    });

    setLastClickTime(now);
  };

  const sendMessage = () => {
    if (!message.trim()) return;
    const chatRef = ref(db, "chat");
    push(chatRef, {
      user: username,
      text: message,
      timestamp: Date.now()
    });
    setMessage("");
  };

  const playTimeLapse = async () => {
    setIsPlayingTimeLapse(true);
    const snapshot = await get(ref(db, "history"));
    const data = snapshot.val();
    if (!data) return;
    const sorted = Object.entries(data).sort((a, b) => a[0] - b[0]);
    const emptyGrid = Array(GRID_SIZE_WIDTH * GRID_SIZE_HEIGHT).fill(DEFAULT_COLOR);
    let tempGrid = [...emptyGrid];
    for (let [key, item] of sorted) {
      tempGrid[item.index] = item.color;
      setGrid([...tempGrid]);
      await new Promise((res) => setTimeout(res, 30));
    }
    setIsPlayingTimeLapse(false);
  };

  return (
    <div className="flex flex-col items-center gap-4 p-4">
	  <h1 className="text-center text-white mt-10 mb-5 text-5xl lg:text-7xl font-bold">Pixel Battle</h1>
      <p className="text-sm text-gray-100">Привет, {username}</p>

      <div className="flex gap-2">
        {COLORS.map((color) => (
          <button
            key={color}
            className="w-8 h-8 border-2 rounded"
            style={{ backgroundColor: color, borderColor: selectedColor === color ? "black" : "white" }}
            onClick={() => setSelectedColor(color)}
          />
        ))}
      </div>

      <button
        onClick={playTimeLapse}
        className="mt-2 px-4 py-1 bg-purple-600 text-white rounded disabled:opacity-50"
        disabled={isPlayingTimeLapse}
      >
        Показать Time-lapse
      </button>

      <div
        className="grid mt-2"
        style={{
          gridTemplateColumns: `repeat(${GRID_SIZE_WIDTH}, ${PIXEL_SIZE}px)`,
          gridTemplateRows: `repeat(${GRID_SIZE_HEIGHT}, ${PIXEL_SIZE}px)`
        }}
      >
        {grid.map((color, index) => (
          <div
            key={index}
            className="border border-gray-200 cursor-pointer"
            style={{
              width: PIXEL_SIZE,
              height: PIXEL_SIZE,
              backgroundColor: color
            }}
            onClick={() => paintPixel(index)}
          />
        ))}
      </div>

      <div className="w-full max-w-md mt-6">
        <h2 className="font-semibold text-gray-100">Чат</h2>
        <div className="h-40 overflow-y-auto border rounded p-2 bg-white">
          {chat.map((entry, idx) => (
            <div key={idx} className="text-sm">
              <strong>{entry.user}:</strong> {entry.text}
            </div>
          ))}
        </div>
        <div className="flex mt-2">
          <input
            className="flex-1 border border-white rounded px-2 py-1 text-gray-100"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && sendMessage()}
            placeholder="Введите сообщение..."
          />
          <button
            onClick={sendMessage}
            className="ml-2 px-3 py-1 bg-blue-500 text-white rounded"
          >
            Отправить
          </button>
        </div>
      </div>

      <div className="w-full max-w-md mt-6">
        <h2 className="font-semibold text-gray-100">Статистика</h2>
        <ul className="text-sm bg-white border rounded p-2">
          {Object.entries(stats).map(([name, data]) => (
            <li key={name}>{name}: {data.count} пикселей</li>
          ))}
        </ul>
      </div>
    </div>
  );
}
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCGhLDVJr5FJ6P0jwrtmchbiukAY3YJ5qU",
  authDomain: "pixelbattle-bd1ba.firebaseapp.com",
  databaseURL: "https://pixelbattle-bd1ba-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "pixelbattle-bd1ba",
  storageBucket: "pixelbattle-bd1ba.firebasestorage.app",
  messagingSenderId: "763120318229",
  appId: "1:763120318229:web:a27b9bba23c37c7fc91b1c",
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const auth = getAuth(app);

export { db, auth };